import React from "react";
import "./educationpage.css";
import EducationCards from "../components/EducationComps/EducationCards";
import { useNavigate, Link } from "react-router-dom";
import Ed from "../images/indTra.webp";
import Reg from "../images/reg_student.png";
import BootcampImage from "../images/bootcamp.webp";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import ChevronRight from "../images/chevron_right_blue.png";
import GDay from "../images/gamedaypic.webp";
import Hackathon from "../images/hackathonpic.png";
import CDay from "../images/careerpic.png";
import innovate from "../images/innovatepic.png";

export default function Education() {
  const location = useLocation();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);

  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  return (
    <div>
      <div className="education-main-parent">
        <div className="education-navbar">
          <a href="/StandardCourses">Regular Courses</a>
          <a href="">Certified Courses</a>
        </div>
        <div className="education-center-about">
          <p>Education at Deebug</p>
          <h1>
            Committed to Excellence <br /> and Innovation
          </h1>
          <h2>
            We believe in shaping the next generation of tech leaders and
            professionals, equipping them with the skills, knowledge, and
            mindset needed to thrive in the ever-evolving digital landscape.
          </h2>
        </div>
        <div className="education-branches">
          <div className="education-branch1">
            <div className="education-branch1-img">
              <img src={Ed} width="100%" height="100%" alt="icon" />
            </div>
            <p>Regular Courses</p>
            <h1>Building the foundation for your career journey</h1>
            <div className="education-learn-btn">
              <Link to="/StandardCourses" className="edu-section_learn_more">
                Learn more
              </Link>
              <img src={ChevronRight} className="edu-learn_more_icon" />
            </div>
          </div>
          <div className="education-branch2">
            <div className="education-branch1-img">
              <img src={BootcampImage} width="100%" height="100%" alt="icon" />
            </div>
            <p>Certified Courses</p>
            <h1>Unlocking the door to career opportuinites</h1>
            <div className="education-learn-btn">
              <Link to="/StandardCourses" className="edu-section_learn_more">
                Learn more
              </Link>
              <img src={ChevronRight} className="edu-learn_more_icon" />
            </div>
          </div>
        </div>
        <div className="education-why">
          <h1>Why Deebug?</h1>
          <div className="education-why-details">
            <div className="education-why-content">
              <p>
                Flexible Tuition Payment.{" "}
                <span>
                  {" "}
                  We don’t think financial capacity should be a barrier. Our
                  flexible tuition option ensure you focus on your learning, not
                  tuition.
                </span>
              </p>
            </div>
            <div className="education-why-content">
              <p>
                Get Help when Needed.{" "}
                <span>
                  {" "}
                  No matter the problem you face in your journey to mastery, we
                  are always there to help you to the next level.
                </span>
              </p>
            </div>
            <div className="education-why-content">
              <p>
                Project based learning.{" "}
                <span>
                  {" "}
                  Our hands-on projects empower students to apply theoretical
                  knowledge, fostering practical skills and innovative thinking
                  for real-world success.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="education-sprint-events">
          {mobile ? (
            <p className="education-sprint-title" style={{ margin: "0" }}>
              SkillSprint
              <br />
              Events
            </p>
          ) : (
            <p className="education-sprint-title" style={{ margin: "30px 0" }}>
              SkillSprint Events
            </p>
          )}
          <div className="education-sprint-act">
            <div className="education-sprint-img">
              <img src={GDay} width="100%" height="100%" alt="event-img" />
            </div>
            <div className="education-sprint-content">
              <h1>Game day</h1>
              <p>
                See how student engage in epic game battles unleashing the
                creativity and team spirit
              </p>
              <div className="education-learn-btn">
                <Link to="/gaming" className="edu-section_learn_more">
                  Learn more
                </Link>
                <img src={ChevronRight} className="edu-learn_more_icon" />
              </div>
            </div>
          </div>
          <div className="education-sprint-act">
            <div className="education-sprint-img">
              <img src={Hackathon} width="100%" height="100%" alt="event-img" />
            </div>
            <div className="education-sprint-content">
              <h1>Hackathon</h1>
              <p>
                Engage in coding challenges and design sprints, where students
                collaborate, ideate, and showcase their skills in friendly
                competitions.
              </p>
              <div className="education-learn-btn">
                <Link to="/hackathon" className="edu-section_learn_more">
                  Learn more
                </Link>
                <img src={ChevronRight} className="edu-learn_more_icon" />
              </div>
            </div>
          </div>
          <div className="education-sprint-act">
            <div className="education-sprint-img">
              <img src={CDay} width="100%" height="100%" alt="event-img" />
            </div>
            <div className="education-sprint-content">
              <h1>Career Expedition</h1>
              <p>
                Discover groundbreaking projects and insights into students
                learning journey.
              </p>
              <div className="education-learn-btn">
                <Link to="/CareerExpedition" className="edu-section_learn_more">
                  Learn more
                </Link>
                <img src={ChevronRight} className="edu-learn_more_icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
