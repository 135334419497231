import React from "react";
import "./MegaMenu.css";
import { useLocation } from "react-router-dom";

export default function MegaMenu() {
  const location = useLocation();

  const isWhiteBackgroundPath =
    location.pathname === "/education" ||
    location.pathname === "/maintenance" ||
    location.pathname === "/BootcampPage" ||
    location.pathname === "/Medplan" ||
    location.pathname === "/ThirdParties" ||
    location.pathname === "/Ethics" ||
    location.pathname === "/" ||
    location.pathname === "/deebug_leadership" ||
    location.pathname === "/deebug_leadership_view" ||
    location.pathname === "/RegularTrainingRegistration" ||
    location.pathname === "/BootcampRegistration" ||
    location.pathname === "/naijapharm" ||
    location.pathname === "/InnovativeShowcase" ||
    location.pathname === "/ITregistration" ||
    location.pathname === "/StandardCourses" ||
    location.pathname === "/contact" ||
    location.pathname === "/CareerExpedition";

  const backgroundColor = isWhiteBackgroundPath ? "white" : "black";
  const textColor = isWhiteBackgroundPath ? "black" : "white";

  return (
    <div className="mega_box" style={{ backgroundColor, color: textColor }}>
      <div className="content">
        <div className="row">
          <header style={{ color: textColor }}>Explore Education</header>
          <ul className="mega_links">
            <li>
              <a href="/education" style={{ color: textColor }}>
                Explore Education
              </a>
            </li>
            <li>
              <a href="/StandardCourses" style={{ color: textColor }}>
                Regular Courses
              </a>
            </li>
            <li>
              <a href="/maintenance" style={{ color: textColor }}>
                Certified Courses
              </a>
            </li>
            <li>
              <a href="/IndustrialTraining" style={{ color: textColor }}>
                Industrial Training
              </a>
            </li>
            <li>
              <a href="/RegularStudents" style={{ color: textColor }}>
                Regular Training
              </a>
            </li>
            <li>
              <a href="/BootcampPage" style={{ color: textColor }}>
                Bootcamp
              </a>
            </li>
          </ul>
        </div>

        <div className="sub_row">
          <header style={{ color: textColor }}>Courses</header>
          <ul className="mega_links">
            <li>
              <a href="/BackendDevelopment" style={{ color: textColor }}>
                Backend Web Development
              </a>
            </li>
            <li>
              <a href="/FrontendDevelopment" style={{ color: textColor }}>
                Frontend Web Development
              </a>
            </li>
            <li>
              <a href="/MobileAppDevelopment" style={{ color: textColor }}>
                Mobile App Development
              </a>
            </li>
            <li>
              <a href="/productdesign" style={{ color: textColor }}>
                Product Design
              </a>
            </li>
            {/* <li>
              <a href="/EmbeddedSystems" style={{ color: textColor }}>Embedded Systems  </a>
            </li> */}
            <li>
              <a href="/FullstackWebDevelopment" style={{ color: textColor }}>
                Fullstack Web Development
              </a>
            </li>
            <li>
              <a href="/FullstackMobileApp" style={{ color: textColor }}>
                Fullstack Mobile App Development
              </a>
            </li>
            <li>
              <a href="/FullstackProductDesign" style={{ color: textColor }}>
                Fullstack Product Design
              </a>
            </li>
            <li>
              <a href="/DataScience" style={{ color: textColor }}>
                Data Science
              </a>
            </li>
            <li>
              <a href="/CyberSecurity" style={{ color: textColor }}>
                Cyber Security
              </a>
            </li>
            <li>
              <a href="/ComputerNetworking" style={{ color: textColor }}>
                Computer Networking
              </a>
            </li>
          </ul>
        </div>

        <div className="sub_row">
          <header style={{ color: textColor }}>More on education</header>
          <ul className="mega_links">
            <li>
              <a href="/hackathon" style={{ color: textColor }}>
                Hackathon
              </a>
            </li>
            <li>
              <a href="/gaming" style={{ color: textColor }}>
                Game Day
              </a>
            </li>
            <li>
              <a href="/CareerExpedition" style={{ color: textColor }}>
                Career Expedition
              </a>
            </li>
            <li>
              <a href="/InnovativeShowcase" style={{ color: textColor }}>
                Innovation Showcase
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
