import React, {useState} from "react";
import './MegaMenu.css';
import { useLocation } from "react-router-dom";

import SearchIcon from '../../images/search_icon_white.png';
import ArrowIcon from '../../images/arrow_icon.png';

export default function MegaMenuSearch(){  
    const location = useLocation();
    const [searchString, setSearchString] = useState('');

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchString(e.target.value);
    }
  
    const isWhiteBackgroundPath = 
    location.pathname === "/education" || 
    location.pathname === "/maintenance" || 
    location.pathname === "/BootcampPage" || 
    location.pathname === "/Medplan" ||   
    location.pathname === "/events" ||   
    location.pathname === "/ThirdParties" ||   
    location.pathname === "/naijapharm" ||
    location.pathname === "/" || 
    location.pathname === "/InnovativeShowcase" ||
    location.pathname === "/deebug_leadership" || 
    location.pathname === "/RegularTrainingRegistration" ||
    location.pathname === "/BootcampRegistration" ||
    location.pathname === "/ITregistration" ||
    location.pathname === "/Ethics" ||
    location.pathname === "/StandardCourses" ||
    location.pathname === "/deebug_leadership_view" || 
    location.pathname === "/contact" || 
    location.pathname === "/CareerExpedition" || 
    location.pathname === "/IndustrialTrainingCourses";
  
    const backgroundColor = isWhiteBackgroundPath ? "white" : "black";
    const textColor = isWhiteBackgroundPath ? "black" : "white";
  
    return (
        <div className="mega_box" style={{ backgroundColor, color: textColor }}>
            <div className="content">
                <div className="row">
                    <div className="text_field">
                    <img
                        src={SearchIcon}
                        className="prefix_icon"
                    />
                    <input name="search"
                        placeholder="Search deebug.com"
                        value={searchString}
                        onChange={handleSearch}
                        className="search_field"
                        />
                    </div>
                    <header style={{ color: textColor }}>Quick links</header>
                    <ul className="mega_links">
                        <li>
                            <a 
                                style={{fontWeight: 400, color: textColor}}
                                href="/maintenance">                                
                                <img 
                                    src={ArrowIcon}
                                    className="arrow_icon"
                                />
                                About Deebug
                            </a>
                        </li>
                        <li>
                            <a 
                                style={{fontWeight: 400, color: textColor}}
                                className='search_quick_link' 
                                href="/maintenance">
                                <img 
                                    src={ArrowIcon}
                                    className="arrow_icon"
                                />
                                Upcoming games
                            </a>
                        </li>
                        <li>
                            <a 
                                style={{fontWeight: 400, color: textColor}}
                                className='search_quick_link' 
                                href="/maintenance">
                                <img 
                                    src={ArrowIcon}
                                    className="arrow_icon"
                                />
                                NaijaPharms                                
                            </a>
                        </li>
                        <li>
                            <a 
                                style={{fontWeight: 400, color: textColor}}
                                className='search_quick_link' 
                                href="/education">
                                <img 
                                    src={ArrowIcon}
                                    className="arrow_icon"
                                />
                                Education
                            </a>
                        </li>
                        <li>
                            <a 
                                style={{fontWeight: 400, color: textColor}}
                                className='search_quick_link' 
                                href="/events">
                                <img 
                                    src={ArrowIcon}
                                    className="arrow_icon"
                                />  
                                Events
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}