import React from 'react';
import { Link } from "react-router-dom";
import './standardcourse.css';
import standardcoursebground from "../../images/standardcoursebground.webp";
import portrait from "../../images/standardcourseprt1.webp";
import portrait1 from "../../images/standardcourseprt2.webp";
import standard1vid from "../../images/standard1vid.webp";
import standard2vid from "../../images/standard2vid.webp";
import standard3vid from "../../images/standard3vid.webp";




const Standard_Courses = () => {
  return (
    <div className='standard-main'>
        <div className='standard-title'>
            <div className='standard-pictures-bgroundprt'>
                <img
                    src={portrait}
                    width="100%"
                    height="100%"
                    alt='icon'
                />
            </div>
            <div className='standard-about'>
                <h1>
                    Making <br /> Learning <br /> <span>Accessible</span> <br /> at every level.
                </h1>
                <p>
                    Learning isn't just about gaining knowledge,
                    it's about experiencing a  journey of growth and 
                    self development. We're dedicated to creating immersive 
                    learning environments where students can excel 
                 </p>
            </div>
            <div className='standard-pictures-bgroundprt'>
                <img
                    src={portrait1}
                    width="100%"
                    height="100%"
                    alt='icon'
                />
            </div>
            <div className='standard-pictures-bground'>
                <img
                    src={standardcoursebground}
                    width="75%"
                    height="70%"
                    alt='icon'
                />
            </div>
        </div>
        <div className='standard-content'>
            <h1>Fostering excellence in every aspect of learning</h1>
            <div className="standard-courses-branch">
                <div className='standard-courses-branch1'>
                    <div className='standard-courses-branch1-content'>
                        <h3 className='standard-course-title1'>Regular Students</h3>
                        <h1>Creating the next gen of software engineers.</h1>
                        <p>
                            Software engineering is more than just writing code, 
                            it's about solving problems, bringing innovative solutions,
                            and transforming ideas into real products. At Deebug, we're 
                            dedicated to grooming not just excellent programmers but also 
                            creative thinkers, effective communicators, and ethical leaders.
                        </p>
                        <Link to='/RegularStudents' className='standard-course-btn'>Learn more</Link>
                    </div>
                    <div className='standard-courses-branch1-img'>
                        <img
                            src={standard1vid}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='standard-pic'
                        />
                    </div>
                </div>
                <div className='standard-courses-branch2'>
                    <div className='standard-courses-branch1-img'>
                        <img
                            src={standard2vid}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='standard-pic'
                        />
                    </div>
                    <div className='standard-courses-branch1-content'>
                        <h3 className='standard-course-title2'>Industrial Training</h3>
                        <h1>Crafting future industry experts.</h1>
                        <p>
                            Through our teaching methods, we empower our students 
                            to become experts in their individual fields, equipped 
                            with the right knowledge, skills, and mindset needed 
                            to strive in the tech space.
                        </p>
                        <Link to='/IndustrialTraining' className='standard-course-btn'>Learn more</Link>
                    </div>
                </div>
                <div className='standard-courses-branch1'>
                    <div className='standard-courses-branch1-content'>
                        <h3 className='standard-course-title3'>Bootcamp</h3>
                        <h1>Mentoring & Advancing ready minds</h1>
                        <p>
                            Through our teaching methods, we empower our students to
                            become experts in their individual fields, equipped with 
                            the right knowledge, skills, and mindset needed to strive 
                            in the tech space.
                        </p>
                        <Link to='/BootcampPage' className='standard-course-btn'>Learn more</Link>
                    </div>
                    <div className='standard-courses-branch1-img'>
                        <img
                            src={standard3vid}
                            width="100%"
                            height="100%"
                            alt='icon'
                            className='standard-pic'
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Standard_Courses