import React from "react";
import "./MegaMenu.css";
import { useLocation } from "react-router-dom";

export default function MegaMenuGaming() {
  const location = useLocation();

  const isWhiteBackgroundPath =
    location.pathname === "/education" ||
    location.pathname === "/maintenance" ||
    location.pathname === "/BootcampPage" ||
    location.pathname === "/Medplan" ||
    location.pathname === "/ThirdParties" ||
    location.pathname === "/" ||
    location.pathname === "/deebug_leadership" ||
    location.pathname === "/RegularTrainingRegistration" ||
    location.pathname === "/BootcampRegistration" ||
    location.pathname === "/ITregistration" ||
    location.pathname === "/deebug_leadership_view" ||
    location.pathname === "/naijapharm" ||
    location.pathname === "/StandardCourses" ||
    location.pathname === "/InnovativeShowcase" ||
    location.pathname === "/Ethics" ||
    location.pathname === "/contact" ||
    location.pathname === "/CareerExpedition";

  const backgroundColor = isWhiteBackgroundPath ? "white" : "black";
  const textColor = isWhiteBackgroundPath ? "black" : "white";

  return (
    <div className="mega_box" style={{ backgroundColor, color: textColor }}>
      <div className="content">
        <div className="row">
          <header style={{ color: textColor }}>Explore Gaming</header>
          <ul className="mega_links">
            <li>
              <a href="/explore-gaming" style={{ color: textColor }}>
                Explore Gaming
              </a>
            </li>
            <li>
              <a href="GameTournament" style={{ color: textColor }}>
                Game Tournament
              </a>
            </li>
            <li>
              <a href="/explore-gaming" style={{ color: textColor }}>
                Ghost Fury
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
