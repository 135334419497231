import "./IndustrialTraining.css";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import tick from "../../images/tick.png";
import react from "../../images/react.png";
import figma from "../../images/figma.png";
import node from "../../images/node.png";
import flutter from "../../images/flutter.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronRight from "../../images/chevron_right_blue.png";
import P1 from "../../images/p1.png";
import P2 from "../../images/p2.png";
import P3 from "../../images/p3.png";
import P4 from "../../images/p4.webp";
import P5 from "../../images/p5.webp";
import P6 from "../../images/p6.webp";
import CarouselPrev from "../../images/carousel_prev.png";
import CarouselNext from "../../images/carousel_next.png";
import CourseCard from "../../components/EducationComps/CourseCards";
import { useNavigate } from "react-router-dom";
import FullScreenDialog from "../../components/FullScreenDialog";
import EduStories from "../../components/EducationComps/EduStories";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
// import "./AllCourses.css";
import IndusSection1 from "../../images/industrial.png";
import IndusSection1Mobile from "../../images/IndustrialMobile.png";
import responsivebtn from "../../images/hmbdwn.png";

export default function IndustrialTraining() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const dialogContent = (
    <div>
      <h2>Your Dialog Content Goes Here</h2>
    </div>
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);

    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden"; // Additional measure
  };

  const closeDialog = () => {
    // console.log("tapped");
    setIsDialogOpen(false);
    document.body.style.overflow = "auto";
    document.documentElement.style.overflow = "auto"; // Additional measure
  };

  // Generate an array of 8 items
  const items = Array.from({ length: 1 }, (_, index) => (
    <EduStories index={index} />
  ));

  const containerRef = useRef(null);
  const scrollLeft = () => {
    if (containerRef.current) {
      if (mobile) {
        containerRef.current.scrollBy({
          left: -window.innerWidth * 0.75, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      } else {
        containerRef.current.scrollBy({
          left: -window.innerWidth * 0.2, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      }
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      if (mobile) {
        containerRef.current.scrollBy({
          left: window.innerWidth * 0.75, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      } else {
        containerRef.current.scrollBy({
          left: window.innerWidth * 0.2, // Scroll 50% of the screen width to the left
          behavior: "smooth", // Optional: add smooth scrolling
        });
      }
    }
  };

  return (
    <>
      <div className="rg-sub-navbar">
        <p>INDUSTRIAL TRAINING</p>
        <ul className={isOpen ? "intern-menu active" : "intern-menu"}>
          <li>
            <span onClick={() => navigate("/IndustrialTraining")}>
              Overview
            </span>
          </li>
          <li>
            <span onClick={() => navigate("/IndustrialTrainingCourses")}>
              Courses
            </span>
          </li>
          <li>
            <span
              className="backend-course-sub-navbar-selection2"
              onClick={() => navigate("/ITregistration")}
            >
              Apply
            </span>
          </li>
        </ul>
        <div className="intern-ham-btn">
          <img
            src={responsivebtn}
            width="100%"
            height="100%"
            alt="icon"
            onClick={toggleMenu}
          />
        </div>
        <span
          className="backend-course-sub-navbar-selection1"
          onClick={() => navigate("/ITregistration")}
        >
          Apply
        </span>
      </div>
      <div className="education_parent">
        <div className="ind-tr-section1">
          <div className="ind-tr-section1-item">
            <img
              src={mobile ? IndusSection1Mobile : IndusSection1}
              className="header-section1-img"
            />
          </div>
          <p>Shaping Tomorrow's Pioneers</p>
        </div>

        <div className="ind-tr-section2-bg">
          <p>
            Shaping the next generation of industry experts through
            comprehensive training and hands-on experience, empowering them with
            the skills and knowledge needed to excel in a rapidly evolving
            professional landscape.
          </p>
        </div>

        <div className="intern-experience-about">
          <p>
            <span className="intern-experience-about-red">
              The Deebug’s Experience.
            </span>
            <span className="intern-experience-about-black">
              {" "}
              Education is not just absorbed,{" "}
            </span>
            it's experienced, ensuring that every lesson leaves a lasting
            impact. Through interactive learning, we transform the educational
            journey into a dynamic experience where knowledge is not only gained
            but also lived.
          </p>
          <div className="ind-tr-section3-list">
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">Guide by Experts.</p>

              <p className="ind-tr-column-subtitle">
                Our tutors not only impart knowledge but also inspire and guide
                ensuring that students not only learn theories but also gain a
                understanding of their practical applications.
              </p>
            </div>
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">Immersive learning.</p>

              <p className="ind-tr-column-subtitle">
                Our learning spaces are designed to inspire the exchange of
                ideas, ignite curiosity, and cultivate an atmosphere where
                students only learn from each other.
              </p>
            </div>
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">Get Help when Needed.</p>

              <p className="ind-tr-column-subtitle">
                We transform education into a dynamic and engaging journey
                through interactive learning methods, project-based exploration
                and momentous events
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <p className="it-project-sub">Project</p>
          <p className="ind-tr-section4-title-gradient">Building Tomorrow</p>
          <div className="edu-project-card-list">
            <div className="edu-project-car-item-main">
              <img src={P1} alt="build1" className="edu-project-card-item" />
            </div>
            <div className="edu-project-car-item-main">
              <img src={P3} alt="build1" className="edu-project-card-item" />
            </div>
            <div className="edu-project-car-item-main">
              <img src={P4} alt="build1" className="edu-project-card-item" />
            </div>
            <div className="edu-project-car-item-main">
              <img src={P5} alt="build1" className="edu-project-card-item" />
            </div>
            <div className="edu-project-car-item-main">
              <img src={P6} alt="build1" className="edu-project-card-item" />
            </div>
          </div>
          <p className="ind-tr-section4-details">
            We bridge the gap between classroom knowledge and real-world
            applications by offering hands-on experience through live projects.
            Students not only learn but thrive, gaining practical insights that
            prepare them for success in their chosen fields.
          </p>
        </div>

        <div className="intern-week">
          <p>IT week</p>
          <h1>
            Captivating events designed to enhance your learning experience.{" "}
          </h1>
          <div className="ind-tr-section3-list">
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">
                Hackathon.
                <span className="ind-tr-column-subtitle">
                  An opportunity for students to collaborate, solve problems,
                  and implement all they’ve been taught.
                </span>
              </p>
              <div className="edu-learn-btn-it">
                <a href="/hackathon" className="section_learn_more">
                  {" "}
                  Learn More
                </a>
                <img src={ChevronRight} className="learn_more_icon" />
              </div>
            </div>
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">
                Career expedition.
                <span className="ind-tr-column-subtitle">
                  An event that offers insight into various industries, and
                  career paths students can take in their various fields
                </span>
              </p>
              <div className="edu-learn-btn-it">
                <a href="/CareerExpedition" className="section_learn_more">
                  {" "}
                  Learn More
                </a>
                <img src={ChevronRight} className="learn_more_icon" />
              </div>
            </div>
            <div className="edu-column-layout">
              <p className="ind-tr-column-title">
                Game day.
                <span className="ind-tr-column-subtitle">
                  An event that brings students together to compete and have
                  unforgettable fun
                </span>
              </p>
              <div className="edu-learn-btn-it">
                <a href="/gaming" className="section_learn_more">
                  {" "}
                  Learn More
                </a>
                <img src={ChevronRight} className="learn_more_icon" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="ind-tr-black-bg">
          <p style={{ color: "rgba(243, 243, 243, 0.75)", fontSize: "1.5rem" }}>
            Game day
          </p>
          <p className="ind-tr-section5-title-gradient">Unplugged Excitement</p>
          <p className="ind-tr-section5-details">
            An unparalleled gaming experience that goes beyond the ordinary.
            This event brings together avid gamers for a day filled with
            thrilling game tournaments and electrifying competitions where every
            move counts and excitement knows no bounds.
          </p>

          <div style={{ display: "flex", justifyContent: "left" }}>
            <a className="section_learn_more" href="#">
              Learn More
            </a>
            <img src={ChevronRight} className="learn_more_icon" />
          </div>
        </div> */}
      </div>

      <div
        style={
          mobile
            ? {
                paddingTop: "40px",
              }
            : {
                height: "600px",
                padding: "50px 0",
                // backgroundColor: "brown",
              }
        }
      >
        <p className="ind-tr-section6-title">Explore our stories</p>

        <div className="stories-parent-container">
          <div ref={containerRef} className="stories-sub-parent-container">
            {items.map((item) => (
              <div
                key={item}
                style={{
                  margin: "30px",
                }}
                onClick={() => {
                  openDialog();
                }}
              >
                {" "}
                {item}
              </div>
            ))}
          </div>

          <div
            onClick={scrollLeft}
            style={
              mobile
                ? {
                    position: "absolute",
                    top: "95%",
                    left: "40%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }
                : {
                    position: "absolute",
                    top: "95%",
                    left: "47%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }
            }
          >
            <img
              src={CarouselPrev}
              alt="Previous"
              className="stories-button-style"
            />
          </div>
          <div
            onClick={scrollRight}
            style={
              mobile
                ? {
                    position: "absolute",
                    top: "95%",
                    right: "40%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }
                : {
                    position: "absolute",
                    top: "95%",
                    right: "47%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }
            }
          >
            <img
              src={CarouselNext}
              alt="Next"
              className="stories-button-style"
            />
          </div>
        </div>
      </div>
      {/*  */}

      <div>
        <div className="ind-tr-black-bg">
          <p style={{ color: "rgba(243, 243, 243, 0.75)", fontSize: "1.5rem" }}>
            Courses
          </p>
          <p className="ind-tr-section7-title-gradient">Igniting Minds</p>
          <p className="ind-tr-section7-subtitle">
            Our courses are meticulously designed to provide you with the most
            relevant, up-to-date knowledge in your chosen field.
          </p>
          <br />
          <br />
          <br />
          <div className="intern-courses-main-container">
            <div className="intern-courses-card2">
              <p className="course-title">BACKEND DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={node}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>NodeJS</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">54K</span>
              </div>
              <Link to="/BackendDevelopment" className="course-btn-style">
                Get started
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes HTML, CSS, Javascript</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>8 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
              </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">FRONTEND DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={react}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>React</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">54k</span>
              </div>
              <Link to="/FrontendDevelopment" className="course-btn-style">
                Get started
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes HTML, CSS, Javascript</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>8 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
              </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">MOBILE APP DEVELOPMENT</p>
              <div className="course-image-and-title">
                <img
                  src={flutter}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>Flutter</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">56K</span>
              </div>
              <Link to="/MobileAppDevelopment" className="course-btn-style">
                Get started
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes HTML, CSS, Javascript</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>8 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
              </div>
            </div>
            <div className="intern-courses-card2">
              <p className="course-title">PRODUCT DESIGN</p>
              <div className="course-image-and-title">
                <img
                  src={figma}
                  width="100%"
                  height="100%"
                  alt="icon"
                  className="course-image"
                />
                <p>Figma</p>
              </div>
              <div className="amount-list">
                <span className="naira-symbol">&#x20A6;</span>
                <span className="amount-style">52K</span>
              </div>
              <Link to="/productdesign" className="course-btn-style">
                Get started
              </Link>
              <div className="all-courses-program-container">
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>Includes HTML, CSS, Javascript</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>8 weeks Intensive + Practical Classes</span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Full access to the interactive community to aid with
                    assignments
                  </span>
                </div>
                <div className="all-courses-program">
                  <img src={tick} className="tick_style" />
                  <span>
                    Certificates are awarded at the end of the program.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="industrial-courses-view-more">
            <div className="edu-learn-btn-it2">
              <a
                href="/IndustrialTrainingCourses"
                className="section_learn_more"
              >
                {" "}
                View all
              </a>
              <img src={ChevronRight} className="learn_more_icon" />
            </div>
          </div>
          <FullScreenDialog
            isOpen={isDialogOpen}
            onClose={closeDialog}
            content={dialogContent}
          />
        </div>
      </div>
    </>
  );
}
