import React from "react";
import "./MegaMenu.css";
import { useLocation } from "react-router-dom";

  

export default function MegaMenuEngineering() {
  
  const location = useLocation();

  const isWhiteBackgroundPath = 
  location.pathname === "/education" || 
  location.pathname === "/maintenance" || 
  location.pathname === "/BootcampPage" || 
  location.pathname === "/Medplan" ||  
  location.pathname === "/ThirdParties" ||   
  location.pathname === "/" || 
  location.pathname === "/deebug_leadership" || 
  location.pathname === "/RegularTrainingRegistration" ||
  location.pathname === "/BootcampRegistration" ||
  location.pathname === "/ITregistration" ||
  location.pathname === "/naijapharm" ||
  location.pathname === "/deebug_leadership_view" || 
  location.pathname === "/InnovativeShowcase" ||
  location.pathname === "/StandardCourses" ||
  location.pathname === "/Ethics" ||
  location.pathname === "/contact" || 
  location.pathname === "/CareerExpedition"


  const backgroundColor = isWhiteBackgroundPath ? "white" : "black";
  const textColor = isWhiteBackgroundPath ? "black" : "white";

  return (
    <div className="mega_box"  style={{ backgroundColor, color: textColor }}>
      <div className="content">
        <div className="row">
          <header style={{ color: textColor }}>Explore Our Products</header>
          <ul className="mega_links" >
          <li><a href="/engineering" style={{ color: textColor }}>Explore Engineering</a></li>
            <li>
              <a href="/Heurekka" style={{ color: textColor }}>Heurekka</a>
            </li> 
            <li>
              <a href="/Medplan" style={{ color: textColor }}>MedPlan App</a>
            </li>
            <li>
              <a href="/naijapharm" style={{ color: textColor }}>NaijaPharms App</a>
            </li> 
          </ul>
        </div>
        <div className="sub_row">
          <header style={{ color: textColor }}>Courses</header>
          <ul className="mega_links">
            <li>
              <a href="/FrontendDevelopment" style={{ color: textColor }}>Website Development</a>
            </li>
            <li>
              <a href="/MobileAppDevelopment" style={{ color: textColor }}>Mobile App Development</a>
            </li>
            <li>
              <a href="/productdesign" style={{ color: textColor }}>Product Design</a>
            </li>
            {/* <li>
              <a href="/">Game Development</a>
            </li>
            <li>
              <a href="/">Video Editing & Production</a>
            </li>
            <li>
              <a href="/">3D Modeling & Animation</a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
