import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronRight from "../../images/chevron_right_blue.png";
// import ChevronRight from "../../images/chevron_right_blue.png";
import ChevronDown from "../../images/arrow_down.png";
import RegSection1 from "../../images/regularpagebground.webp";
import RegSection1Mobile from "../../images/regularpagebgroundprt.webp";
import ELearning from "../../images/e_learning.png";
import HandsOn from "../../images/hands_on.png";
import HandsOn1 from "../../images/hack1.webp";
import regsect1 from "../../images/regsect1.png";
import regfun from "../../images/regularcoursefun.webp";
import grad from "../../images/regulargrad.png";
import regsect from "../../images/p3.png";
import CourseCard from "../../components/EducationComps/CourseCards";
import { useEffect, useState } from "react";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import responsivebtn from '../../images/hmbdwn.png';
// import "./Hackathon.css";
import "./RegularStudents.css";

export default function RegularStudents() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  

  return (
    <div className="regular-main-page">
    <div className="rg-sub-navbar">
      <p>Regular Students</p>
        <ul className={isOpen ? "intern-menu active" : "intern-menu"}>
          <li><span onClick={() => navigate("/RegularStudents")}>Overview</span></li>
          <li><span onClick={() => navigate("/allcourses")}>Courses</span></li>
          <li><span className="backend-course-sub-navbar-selection2" onClick={() => navigate("/RegularTrainingRegistration")}>Apply</span></li>
        </ul>
      <div className="intern-ham-btn">
        <img 
          src={responsivebtn}
          width="100%"
          height="100%"
          alt='icon'
          onClick={toggleMenu}
        />
      </div>
      <span className="backend-course-sub-navbar-selection1" onClick={() => navigate("/RegularTrainingRegistration")}>Apply</span>
    </div>
      <div className="bootcamp-section1-item">
        <img
          src={mobile ? RegSection1Mobile : RegSection1}
          className="header-section1-img"
        />
      </div>

      <div className="regular-section2-container">
        <p className="reg-section2-main-title">Teach. Innovate. Inspire</p>
        <p className="reg-section2-subtitle">
          In the realm of education, our mantra is simple yet powerful. We believe 
          in not just transferring knowledge but sparking innovation and igniting 
          inspiration. Our commitment is to foster an environment where teaching 
          Dynamic, innovation fuels curiosity, and inspiration becomes the driving 
          force for lifelong learning.
        </p>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "2rem", }}>
          <a className="hackathon_more" href="/allcourses">
            Explore courses
          </a>
          <img src={ChevronDown} className="hackathon_more_icon" />
        </div>
      </div>
      <div className="regular-student-container1">
        <div className="reg-section3-bg">
          <p className="rg-section3-title">Teaching and Coaching</p>
          <p className="rg-section3-subtitle">
            Teaching goes beyond imparting knowledge. It's a journey of
            empowerment. Our dedicated educators are not just instructors but
            mentors, guiding students towards a deeper understanding and
            application of concepts. Through coaching, we nurture individual
            strengths, fostering a dynamic learning environment.
          </p>
        </div>
        <div className="reg-section4-container">
          <div className="reg-section4-info-group">
            <p>
              <span style={{ color: "rgba(200, 0, 69, 1)" }}>
                Online Learning.
              </span>{" "}
              Dive into a world where education knows no boundaries, and your
              quest for knowledge is just a click away.
            </p>
          </div>
          <img src={ELearning} className="reg-section4-img" alt="" />
        </div>
        <div className="reg-section1-bg">
          <div className="reg-section7-bg">
            <p className="rg-section3-title">Gain Hands-on Practical Experience in your chosen course of study.</p>
            <div className="reg-section7-img">
              <img 
                src={HandsOn1}
                width="100%"
                height="100%"
                alt="icon"
                />
            </div>
          </div>
          <div className="reg-section8-bg">
            <div className="reg-section8-img">
              <img 
                src={regsect}
                width="100%"
                height="100%"
                alt="icon"
                />
            </div>
            <p>We are committed to a <span>practical learning approach</span> ensuring that you not only grasp the theory but also develop the practical expertise needed by engaging you in practical exercises.</p>
          </div>
        </div>
      </div>

      <div className="regular-student-container2">
        <div className="reg-section2-bg">
          <p className="rg-section2-title">Explore Beyond Academics</p>
          <p className="rg-section2-subtitle">
            our commitment goes beyond traditional teaching methods. 
            We believe in providing immersive experiences that foster 
            creativity, collaboration, and innovation. We offer a diverse 
            range of events designed to ignite passion, broaden horizons, 
            and unleash the full potential of our students.
          </p>
        </div>
        <div className="reg-section4-container">
          <div className="reg-section2-info-group">
            <p>
              Unforgettable events like Game day, <span>Hackathon, 
              Career expedition,</span> and<span> Innovation showcase </span> 
              are designed to enhance your learning journey.
            </p>
          </div>

          <img src={regfun} className="reg-section4-img" alt="" />
        </div>
        <div className="reg-section1-bg">
          <div className="reg-section7-bg">
            <p className="rg-section2-title">Curriculum designed to empower you with the skills needed to excel. </p>
            <div className="reg-section7-img">
              <img 
                src={HandsOn}
                width="90%"
                height="100%"
                alt="icon"
                />
            </div>
          </div>
          <div className="reg-section9-bg">
            <div className="reg-section8-img">
              <img 
                src={regsect1}
                width="100%"
                height="100%"
                alt="icon"
                />
            </div>
            <p>
              Our program is designed to equip you with the <span>essential skills </span>
              and <span>knowledge</span> required to not just succeed, but thrive. From basic principles,
              each module is designed to help you develop the right skill set to thrive.
            </p>
          </div>
        </div>
      </div>
      <div className="regular-student-container5">
        <div className="reg-section3-bg">
          <p
            className="rg-section3-title"
            style={{ color: "rgba(103, 140, 178, 1)" }}
          >
            Bringing out the Best.
          </p>
          <p className="rg-section3-subtitle">
            We pride ourselves on producing graduates who are not only well-prepared but truly exceptional, as our rigorous academic programs, teaching methods, and emphasis on real-world experience ensure that our graduates stand out in the competitive tech landscape, reflecting our commitment to nurturing talent and fostering excellence.
          </p>
        </div>     
        <div className="reg-section4-container">
          <div className="reg-section4-info-group">
            <p>
              <span style={{ color: "rgba(103, 140, 178, 1)" }}>Certification.</span>{" "}
              Upon successful completion of your course, you'll be awarded a
              prestigious certificate. This testament to your achievements not
              only acknowledges your dedication but also opens doors to new
              opportunities in the tech world.
            </p>
          </div>

          <img src={grad} className="reg-section5-img" alt="" />
        </div>
      </div>

      <div className="boot-section2-container">
        <p className="reg-section2-main-title">Get Started Today</p>
        <p className="reg-section2-subtitle">
          Ready to dive into a world of knowledge? Get started with our courses
          and unlock a path to endless possibilities. Your educational journey
          begins here.
        </p>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <a className="hackathon_more" href="/contact">
            Register now
          </a>
          <img src={ChevronRight} className="learn_more_icon" />
        </div>

        <p className="reg-contact-details">
          Contact Deebug Institute at{" "}
          <span className="reg-contact-phone">+2349162627332</span>
        </p>
      </div>
      
    </div>
  );
}