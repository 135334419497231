// FullScreenDialog.js
// import React from "react";
import Modal from "react-modal";
import Cancel from "../images/cancel.png";
import Game from "../images/game_match.png";
import Person from "../images/stories1.png";
import "./FullScreenDialog.css";

import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Purpose1 from "../images/Purpose1.jpg";
// import Purpose2 from "../images/Purpose2.JPG";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";

import Purpose1 from "../images/pur1.png";
import Purpose2 from "../images/pur2.png";
import Purpose1mobile from "../images/pur1_mobile.png";
import Purpose2mobile from "../images/pur2_mobile.png";

const FullScreenDialog = ({ isOpen, onClose, content }) => {
  const [mobile, setMobile] = useState(false);
  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        style={{ padding: 0 }}
        // maxWidth="md"
      >
        {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}

        <DialogContent style={{ padding: 0 }}>
          <DialogContentText>
            <div>
              <img
                src={mobile ? Purpose1mobile : Purpose1}
                className="full-modal-image"
              />
              <img
                src={Cancel}
                onClick={onClose}
                className="full-modal-cancel-icon"
              />
            </div>

            <div style={mobile ? { padding: "10px" } : { padding: "50px" }}>
              <p className="name-style">Purpose Olikiabo</p>
              <p className="info-style">Studied Computer Science</p>
              <p className="info-style">University of Benin, class 2023</p>
              <p className="comment-style">
                “Deebug’s innovative education approach shaped my tech skills
                and fueled continuous creativity. Grateful for the
                transformative journey.”
              </p>
              {/* <p style={{ fontWeight: "bold" }}>Bold Text:</p> */}
              <p className="info-style">
                From a quiet observer to a design virtuoso, Purpose
                transformation is nothing short of extraordinary. Once reserved,
                she embarked on her journey at Deebug Institute that not only
                polished her design skills but also brought her out of her
                shell. <br />
                <br /> {"\n"}
                {"\n"}In the beginning, Purpose was a girl who preferred the
                solitude of her thoughts, but During her time at the institute,
                Purpose not only honed her technical design expertise but also
                discovered the power of effective communication. Through
                engaging projects, collaborative workshops, and mentorship,
                Purpose developed not only as a skilled designer but also as a
                confident communicator.
              </p>
            </div>

            <img
              src={mobile ? Purpose2mobile : Purpose2}
              className="full-modal-image"
            />

            <div style={mobile ? { padding: "10px" } : { padding: "50px" }}>
              <p className="info-style">
                Upon graduation from the institute, Purpose has undergone an
                evident transformation. Armed with a robust portfolio and
                newfound communication skills, she delved into the professional
                world. Purpose creative prowess and ability to articulate design
                concepts set her apart in the industry.{"\n"}
                <br />
                <br />
                {"\n"}
                Since then, Purpose has contributed to several design projects,
                leaving her mark on diverse creative landscapes. Her success
                story stands as a testament to the transformative power of
                education and mentorship. {"\n"}
                <br />
                <br />
                Below are links to some of the projects Purpose has played a
                pivotal role in designing{" "}
              </p>
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogContent dividers>
          <DialogContentText>
            <a href="https://deebug.org/">
              https://deebug.org/
            </a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullScreenDialog;

// const FullScreenDialog = ({ isOpen, onClose, content }) => {
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onClose}
// scroll={body}
//       contentLabel="Full Screen Dialog"
//       ariaHideApp={false} // Disable accessibility warning

//       className="custom-modal"
//       style={{
//         overlay: {
//           backgroundColor: " rgba(0, 0, 0, 0.8)",
//         },
//       }}
//     >
//       {" "}
//       <img src={Cancel} onClick={onClose} className="full-modal-cancel-icon" />
//       <img src={Person} className="full-modal-image" />
//       <div className="text-deco">
//         <p className="name-style">Ohis Olikiabo</p>
//         <p className="info-style">Studied Computer Science</p>
//         <p className="info-style">University of Benin, class 2023</p>
//         <p className="comment-style">
//           “Deebug’s innovative education approach shaped my tech skills and
//           fueled continuous creativity. Grateful for the transformative
//           journey.”
//         </p>
//       <p >
//           From a quiet observer to a design virtuoso, Purpose transformation is
//           nothing short of extraordinary. Once reserved, she embarked on her
//           journey at Deebug Institute that not only polished her design skills
//           but also brought her out of her shell.
//           <br />
//           <br />
//           In the beginning, Purpose was a girl who preferred the solitude of her
//           thoughts, but During her time at the institute, Purpose not only honed
//           her technical design expertise but also discovered the power of
//           effective communication. Through engaging projects, collaborative
//           workshops, and mentorship, Purpose developed not only as a skilled
//           designer but also as a confident communicator.
//         </p>
//       </div>
//       <img src={Game} className="full-modal-image" />
//       <p className="text-deco">
//         Upon graduation from the institute, Purpose has undergone an evident
//         transformation. Armed with a robust portfolio and newfound communication
//         skills, she delved into the professional world. Purpose creative prowess
//         and ability to articulate design concepts set her apart in the industry.
//         Since then, Purpose has contributed to several design projects, leaving
//         her mark on diverse creative landscapes. Her success story stands as a
//         testament to the transformative power of education and mentorship. Below
//         are links to some of the projects Purpose has played a pivotal role in
//         designing
//       </p>

//     </Modal>
//   );
// };

// export default FullScreenDialog;
