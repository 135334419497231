import React from "react";
import './MegaMenu.css';
import { useLocation } from "react-router-dom";

export default function MegaMenuHealthcare(){  
  
    const location = useLocation();
  
    const isWhiteBackgroundPath = 
    location.pathname === "/education" || 
    location.pathname === "/maintenance" || 
    location.pathname === "/BootcampPage" || 
    location.pathname === "/Medplan" ||  
    location.pathname === "/ThirdParties" ||   
    location.pathname === "/" || 
    location.pathname === "/deebug_leadership" || 
    location.pathname === "/deebug_leadership_view" || 
    location.pathname === "/RegularTrainingRegistration" ||
    location.pathname === "/BootcampRegistration" ||
    location.pathname === "/naijapharm" ||
    location.pathname === "/InnovativeShowcase" ||
    location.pathname === "/ITregistration" ||
    location.pathname === "/StandardCourses" ||
    location.pathname === "/Ethics" ||
    location.pathname === "/contact" || 
    location.pathname === "/CareerExpedition" 
  
  
    const backgroundColor = isWhiteBackgroundPath ? "white" : "black";
    const textColor = isWhiteBackgroundPath ? "black" : "white";
  
    return (
        <div className="mega_box" style={{ backgroundColor, color: textColor }}>
            <div className="content">
                <div className="row">
                    <header style={{ color: textColor }}>Explore Healthcare</header>
                    <ul className="mega_links">                    
                        <li><a href="/Healthcare" style={{ color: textColor }}>Explore Healthcare</a></li>
                        <li><a href="/maintenance" style={{ color: textColor }}>MedPlan</a></li>
                        <li><a href="/maintenance" style={{ color: textColor }}>NaijaPharms</a></li>
                    </ul>
                </div>
                <div className="sub_row">
                    <header style={{ color: textColor }}>More on Healthcare</header>
                    <ul className="mega_links">
                        <li><a href="/" style={{ color: textColor }}>Research</a></li>
                        <li><a href="/" style={{ color: textColor }}>News & Events</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}