import React, {useState} from 'react'
import './ethics.css'
import responsivebtn from '../../images/hmbbtn.png';
import read_more_blk from "../../images/read_more_blk.png";
import read_cancel from "../../images/read_cancel.png";
import ethicsbns from "../../images/ethicsbns.png";
import ethicslaw from "../../images/ethicslaw.png";
import ethicslad from "../../images/ethicslad.png";


const Ethics = () => {
    const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);
    const [isReadMoreVisible1, setIsReadMoreVisible1] = useState(false);
    const [isReadMoreVisible2, setIsReadMoreVisible2] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isImageOneVisible, setImageOneVisible] = useState(true);
    const [isImageOneVisible1, setImageOneVisible1] = useState(true);
    const [isImageOneVisible2, setImageOneVisible2] = useState(true);

    const handleImageClick = () => {
        setImageOneVisible(!isImageOneVisible);
    };

    const handleImageClick1 = () => {
        setImageOneVisible1(!isImageOneVisible1);
    };
    
    const handleImageClick2 = () => {
        setImageOneVisible2(!isImageOneVisible2);
    };
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    
    const handleReadClick = () => {
        setIsReadMoreVisible(!isReadMoreVisible);
    };
    const handleReadClick1 = () => {
        setIsReadMoreVisible1(!isReadMoreVisible1);
    };
    const handleReadClick2 = () => {
        setIsReadMoreVisible2(!isReadMoreVisible2);
    };
  

  return (
    <div className='ethics-main'>
        <div className='ethics-top-container'>
            <div className='ethics-topbar'>
                <h1>Ethics and Compliance</h1>
                <ul className={isOpen ? "ethics-menu active" : "ethics-menu"}>
                    <li> <a href='/ThirdParties' className='ethics-link'>Third Parties</a></li>
                    <li> <a href='/RegularTrainingRegistration' className='ethics-link'>Speak Up</a></li>
                </ul>
                <div className="ethics-ham-btn">
                    <img 
                        src={responsivebtn}
                        width="100%"
                        height="100%"
                        alt='icon'
                        onClick={toggleMenu}
                    />
                </div>
            </div>
            <div className='ethics-about'>
                <h1>Ethics</h1>
                <p>
                    Ethics is not just a principle,  it's our guiding 
                    force. As a company, collaboration with our clients is 
                    built on transparency, integrity, respect, and honor. 
                    We prioritize clear communication, uphold the highest 
                    standards of data privacy, and ensure that every solution 
                    we craft aligns seamlessly with our clients' values and 
                    objectives. Additionally, we are dedicated to improvement 
                    not just in education, as we consistently refine our educational 
                    content and course curriculum, but also in our product development 
                    processes, embracing a culture of innovation. We are always seeking 
                    ways to improve and deliver solutions that exceed our clients' 
                    expectations.
                </p>
            </div>
        </div>
        <div className='ethics-content'>
            <div className='ethics-content-child'>
                <div className='ethics-child-title'>
                    <h1>Business dealings with our clients</h1>
                    <p>Transparency, Integrity, Respect, and Honor.</p>
                    <div className='ethics-read'>
                        <div className='ethics-read-icon' onClick={handleReadClick}>
                        {isImageOneVisible ? (
                            <img
                                src={read_more_blk}
                                height='100%'
                                width='100%'
                                alt='icon'
                                className='ethics-read-icon'
                                onClick={handleImageClick}
                            />
                            ) : (
                                <img
                                    src={read_cancel}
                                    height='100%'
                                    width='100%'
                                    alt='icon'
                                className='ethics-read-icon'
                                onClick={handleImageClick}
                                />
                            )}
                        </div>
                        <p>Read</p>
                    </div>
                </div>
                <div className='ethics-child-content'>
                    {!isReadMoreVisible && (
                        <div className='ethics-child-image'>
                            <img
                                src={ethicsbns}
                                height='100%'
                                width='100%'
                                alt='icon'
                                className='ethics-child-icon'
                            />
                        </div>
                    )}
                    {isReadMoreVisible && (
                        <div className='child-ethics-about'>
                            <div className='ethics-read-icon2' onClick={handleReadClick}>                        
                                <img
                                    src={read_cancel}
                                    height='100%'
                                    width='100%'
                                    alt='icon'
                                    className='ethics-read-icon'
                                    onClick={handleImageClick}
                                />
                            </div>
                            <h1>Business dealings with our clients</h1>
                            <p>
                                Our business dealings are guided by principles that prioritize clear
                                communication, integrity, honesty and respect for our clients. 
                                Staffs also uphold these principles, ensuring a harmonious collaboration
                                that places client satisfaction at the forefront of our interactions.
                                Violations of this principle will be treated with the utmost seriousness.
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className='ethics-content-child'>
                <div className='ethics-child-title'>
                    <h1>Compliance at Deebug</h1>
                    <p>Legal, Development and Business practices.</p>
                    <div className='ethics-read'>
                        <div className='ethics-read-icon' onClick={handleReadClick1}>
                        {isImageOneVisible1 ? (
                            <img
                                src={read_more_blk}
                                height='100%'
                                width='100%'
                                alt='icon'
                                className='ethics-read-icon'
                                onClick={handleImageClick1}
                            />
                            ) : (
                                <img
                                    src={read_cancel}
                                    height='100%'
                                    width='100%'
                                    alt='icon'
                                className='ethics-read-icon'
                                onClick={handleImageClick1}
                                />
                            )}
                        </div>
                        <p>Read</p>
                    </div>
                </div>
                <div className='ethics-child-content'>
                    {!isReadMoreVisible1 && (
                        <div className='ethics-child-image'>
                            <img
                                src={ethicslaw}
                                height='100%'
                                width='100%'
                                alt='icon'
                                className='ethics-child-icon'
                            />
                        </div>
                    )}
                    {isReadMoreVisible1 && (
                        <div className='child-ethics-about'>
                            <div className='ethics-read-icon2' onClick={handleReadClick1}>                        
                                <img
                                    src={read_cancel}
                                    height='100%'
                                    width='100%'
                                    alt='icon'
                                    className='ethics-read-icon'
                                    onClick={handleImageClick1}
                                />
                            </div>
                            <h1>Compliance at Deebug</h1>
                            <p>
                                We adhere to stringent compliance standards in our legal, development,
                                and business practices. Our commitment to compliance ensures a secure,
                                ethical, and innovative environment that aligns with industry 
                                regulations and best practices.
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className='ethics-content-child'>
                <div className='ethics-child-title'>
                    <h1>Constantly Improving</h1>
                    <p>Educational content and curriculum, Engineering.</p>
                    <div className='ethics-read'>
                        <div className='ethics-read-icon' onClick={handleReadClick2}>
                        {isImageOneVisible2 ? (
                            <img
                                src={read_more_blk}
                                height='100%'
                                width='100%'
                                alt='icon'
                                className='ethics-read-icon'
                                onClick={handleImageClick2}
                            />
                            ) : (
                                <img
                                    src={read_cancel}
                                    height='100%'
                                    width='100%'
                                    alt='icon'
                                className='ethics-read-icon'
                                onClick={handleImageClick2}
                                />
                            )}
                        </div>
                        <p>Read</p>
                    </div>
                </div>
                <div className='ethics-child-content'>
                    {!isReadMoreVisible2 && (
                        <div className='ethics-child-image'>
                            <img
                                src={ethicslad}
                                height='100%'
                                width='100%'
                                alt='icon'
                                className='ethics-child-icon'
                            />
                        </div>
                    )}
                    {isReadMoreVisible2 && (
                        <div className='child-ethics-about'>
                            <div className='ethics-read-icon2' onClick={handleReadClick2}>                        
                                <img
                                    src={read_cancel}
                                    height='100%'
                                    width='100%'
                                    alt='icon'
                                    className='ethics-read-icon'
                                    onClick={handleImageClick2}
                                />
                            </div>
                            <h1>Constantly Improving</h1>
                            <p>
                                We are committed to constant improvement across our educational 
                                content and curriculum, as well as engineering practices. Through 
                                ongoing refinement and innovation, we strive to deliver high-quality education and cutting-edge 
                                solutions that meet the evolving needs of our clients and learners.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Ethics


