import React, { useState, useEffect } from "react";
import "./landingpage.css";
import landingq2gd from "../images/landingq2gd.webp";
import landingcertified from "../images/landingcertified.webp";
import homepad from "../images/homepad.webp";
import image1 from "../images/landingheurekka.png";
import image2 from "../images/landingmedplan.webp";
import image3 from "../images/landingnaijapharm.webp";
import title1 from "../images/landhratitle.png";
import title2 from "../images/landingmedplantitle.png";
import title3 from "../images/landnaijaphtitle.png";
import titleprt1 from "../images/landinghratitleprt.png";
import titleprt2 from "../images/landmedtitleprt.png";
import titleprt3 from "../images/landnaijaphtitleprt.png";

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const phoneNumber = "+2349162627332";

  const slides = [
    {
      image: image1,
      imageSmall: image1,
      title: title1,
      titleprt: titleprt1,
      titleText: "Our Products",
      link: "/maintenance",
      description:
        "A safe space for developers, designers and creatives to connect, collaborate, share ideas and resources and find jobs.",
    },

    {
      image: image2,
      imageSmall: image2,
      title: title2,
      titleprt: titleprt2,
      titleText: "Our Products",
      link: "/maintenance",
      description:
        "Providing tools and resources which empower individuals to better manage their own health. From Telemedicine to e-pharmacy, we got you covered.",
    },

    {
      image: image3,
      imageSmall: image3,
      title: title3,
      titleprt: titleprt3,
      titleText: "Our Products",
      link: "/maintenance",
      description:
        "Connect & Collaborate with Pharmacists Nationwide. Share knowledge, Find Jobs, and stay up-to-date with all Pharma activities.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="landing-page-main">
      <div className="landing-main-bground">
        <div className="landing-bground">
          <h1> Transform your Tech Career this Industrial Training</h1>
          <p>
            Our Industrial Training program equips you with the in-demand skills
            and real-world experience employers crave, giving you a competitive
            edge and propelling you towards a rewarding career.
          </p>
          <div className="landing-bground-btn">
            <button className="landing-reg-btn">
              {" "}
              <a
                href="https://deebuginstitute.com/RegularTrainingRegistration"
                target="_blank"
              >
                Register Now
              </a>
              {/* <a href="/ITregistration">Register Now</a> */}
            </button>
            <button className="landing-reg-btn1">
              {" "}
              <a href={`https://wa.me/${phoneNumber}`}>Message Now</a>
            </button>
          </div>
        </div>
      </div>
      <div className="landing-certified-course">
        <div className="landing-certified-course-text">
          <div className="landing-cert-img">
            <img src={landingcertified} width="100%" height="100%" alt="icon" />
          </div>
          <h1>Validate your expertise.</h1>
          <p>
            Earn industry recognized certification through our program that sets
            you apart from the competition.
          </p>
          <button className="land-cert-course-btn">
            <a href="/education">Learn more</a>
          </button>
        </div>
      </div>
      <div className="landing-regular-course">
        <div className="landing-regular-course1">
          <h1>Boost Career Readiness</h1>
          <p>
            Bridging the gap between theoretical knowledge and practical
            applications.
          </p>
          <button className="land-cert-course-btn">
            <a href="/IndustrialTraining">Learn more</a>
          </button>
        </div>
        <div className="landing-regular-course2">
          <h1>Empower Your Future</h1>
          <p>
            Looking to develop a new skill in tech, we have something for you.
            From coding basics to design, we have the perfect course to help you
            achieve your goals.
          </p>
          <button className="land-cert-course-btn">
            <a href="regular_students">Learn more</a>
          </button>
        </div>
      </div>
      <div className="landing-slideshow-container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`landing-slide ${
              index === currentIndex ? "active" : ""
            }`}
          >
            <h1> {slide.titleText} </h1>
            <div className="slide-content">
              <img
                className="title"
                src={slide.title}
                width="100%"
                height="100%"
              />
              <img
                className="titleprt"
                src={slide.titleprt}
                width="100%"
                height="100%"
              />
              <div className="description">{slide.description}</div>
              <button
                className="landing-page-slide-btn"
                onClick={() => {
                  //   console.log(slides[index]);
                  //   console.log(slide.link);
                  window.location.href = slide.link;
                }}
              >
                Learn more
              </button>
            </div>
            <img
              src={isSmallScreen ? slide.imageSmall : slide.image}
              alt={slide.title}
              width="100%"
              height="47%"
              className="slide-image"
            />
            <div className="indicators">
              {slides.map((_, index) => (
                <div
                  key={index}
                  className={`indicator ${
                    index === currentIndex ? "active" : ""
                  }`}
                  onClick={() => setCurrentIndex(index)}
                ></div>
              ))}
            </div>
          </div>
        ))}
        {/* <div className="indicators">
                {slides.map((_, index) => (
                <div
                    key={index}
                    className={`indicator ${index === currentIndex ? 'active' : ''}`}
                    onClick={() => setCurrentIndex(index)}
                ></div>
                ))}
            </div> */}
      </div>
      <div className="landing-certified-course3">
        <div className="landing-certified-course-text1">
          <h1>Elevating the Gaming Experience</h1>
          <p>
            Discover the next level of gaming and the future of gameplay,
            providing an immersive and unforgettable experiences.
          </p>
          <button className="land-cert-course-btn">
            <a href="/GameTournament">Learn more</a>
          </button>
        </div>
      </div>
      <div className="landing-regular-course">
        <div className="landing-regular-course3">
          <div className="landing-q2-img">
            <img src={landingq2gd} width="100%" height="100%" alt="icon" />
          </div>
          <h1>Rise to the Challenge</h1>
          <p>Deebug Second Quarter Game Day 2024</p>
          <button className="land-cert-course-btn">
            <a href="/gaming">Learn more</a>
          </button>
        </div>
        <div className="landing-regular-course4">
          <h1>Level up the fun</h1>
          <p>
            A day of of ultimate fun and excitement and most importantly
            non-stop gaming
          </p>
          <button className="land-cert-course-btn">
            <a href="/GameTournament">Learn more</a>
          </button>
          <div className="landing-q3-img">
            <img src={homepad} width="100%" height="100%" alt="icon" />
          </div>
        </div>
      </div>
      <div className="landing-certified-course1">
        <div className="landing-certified-course-text1">
          <h1>Transforming healthcare</h1>
          <p>
            Our digital health solutions are transforming the way healthcare is
            experienced by both individuals and Healthcare providers
          </p>
          <button className="land-cert-course-btn">
            <a href="/Healthcare">Learn more</a>
          </button>
        </div>
      </div>
      {/* <Test /> */}
      {/* <Projects /> */}
    </div>
  );
};

export default LandingPage;
