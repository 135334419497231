import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import ScrollToTop from "./scrollToTop";
import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
// import HomePage from "./pages/HomePage";
import Leadership from "./pages/leadership/Leadership";
import Education from "./pages/Education";
import Engineering from "./pages/Engineering";
import IndustrialTrainingCourses from "./pages/education/IndustrialTrainingCourses";
import Hackathon from "./pages/education/Hackathon";
import Bootcamp from "./pages/education/Bootcamp";
import IndustrialTraining from "./pages/education/IndustrialTraining";
import RegularStudents from "./pages/education/RegularStudents";
import ContactUs from "./pages/contact/ContactUs";
import LeadershipView from "./pages/leadership/LeadershipView";
import Maintenance from "./pages/UnderMaintenance";
import Career_day from "./components/career_expedition/Career_day";
import GeneralGaming from "./components/general_gaming/GeneralGaming";
import Standard_Courses from "./components/standard_courses/Standard_Courses";
import All_Courses from "./pages/education/All_Courses";
import Product_Design from "./components/courses/Product_Design";
import Frontend from "./components/courses/Frontend";
import Embedded_Systems from "./components/courses/Embedded_Systems";
import Backend from "./components/courses/Backend";
import Mobile_Development from "./components/courses/Mobile_Development";
import Fullstack_Web_Dev from "./components/courses/Fullstack_Web_Dev";
import Fullstack_Product from "./components/courses/Fullstack_Product";
import Fullstack_Mobile from "./components/courses/Fullstack_Mobile";
import Innovative_Showcase from "./components/innovative_showcase/Innovative_Showcase";
import Ethics from "./components/ethics/Ethics";
import Third_Parties from "./components/ethics/Third_Parties";
import Events from "./components/events/Events";
import Healthcare from "./pages/healthcare/Healthcare";
import Engineering_Services from "./pages/Engineering_Services";
import Heurekka from "./pages/heurekka/Heurekka";
import Medplan from "./pages/medplan/Medplan";
import Bootcamp_Page from "./pages/education/Bootcamp_Page";
import Game_Tournament from "./components/game_tournament/Game_Tournament";
import Award_Night from "./components/award_night/Award_Night";
import Regular_Registration from "./components/registration/Regular_Registration";
import BootCamp_Registration from "./components/registration/BootCamp_Registration";
import Intern_Registration from "./components/registration/Intern_Registration";
import BootcampBackend from "./components/bootcampcourses/BootcampBackend";
import BootcampFrontend from "./components/bootcampcourses/BootcampFrontend";
import BootcampEmbeddedSystems from "./components/bootcampcourses/BootcampEmbeddedSystems";
import BootcampMobileDevelopment from "./components/bootcampcourses/BootcampMobileDevelopment";
import BootcampFullstackWebDev from "./components/bootcampcourses/BootcampFullstackWebDev";
import BootcampFullstackProduct from "./components/bootcampcourses/BootcampFullstackProduct";
import BootcampFullstackMobile from "./components/bootcampcourses/BootcampFullstackMobile";
import BootcampProductDesign from "./components/bootcampcourses/BootcampProductDesign";
import DataScience from "./components/courses/DataScience";
import CyberSecurity from "./components/courses/CyberSecurity";
import ITBackend from "./components/ITcourses/ITBackend";
import ITEmbeddedSystems from "./components/ITcourses/ITEmbeddedSystems";
import ITMobileDev from "./components/ITcourses/ITMobileDev";
import ITFullstackWeb from "./components/ITcourses/ITFullstackWeb";
import ITFullstackProductDesign from "./components/ITcourses/ITFullstackProductDesign";
import ITFullstackMobileDev from "./components/ITcourses/ITFullstackMobileDev";
import ITFrontend from "./components/ITcourses/ITFrontend";
import ITProductDesign from "./components/ITcourses/ITProductDesign";
import ITCyberSecurity from "./components/ITcourses/ITCyberSecurity";
import ITDataScience from "./components/ITcourses/ITDataScience";
import BootcampDataScience from "./components/bootcampcourses/BootcampDataScience";
import BootcampCyberSecurity from "./components/bootcampcourses/BootcampCyberSecurity";
import Gaming from "./components/gaming/Gaming";
import NaijaPharm from "./components/naijapharm/NaijaPharm";
import ComputerNetworking from "./components/courses/ComputerNetworking";
import BootcampComputerNetworking from "./components/bootcampcourses/BootcampComputerNetworking";
import ITComputerNetworking from "./components/ITcourses/ITComputerNetworking";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />}>
          <Route path="" element={<LandingPage />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/deebug_leadership" element={<Leadership />} />
          <Route path="/deebug_leadership_view" element={<LeadershipView />} />
          <Route path="/education" element={<Education />} />
          <Route path="/engineering" element={<Engineering />} />
          <Route path="/RegularStudents" element={<RegularStudents />} />
          <Route path="/IndustrialTraining" element={<IndustrialTraining />} />
          <Route
            path="/IndustrialTrainingCourses"
            element={<IndustrialTrainingCourses />}
          />
          <Route path="/hackathon" element={<Hackathon />} />
          <Route path="/bootcamp" element={<Bootcamp />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/CareerExpedition" element={<Career_day />} />
          <Route path="/gaming" element={<GeneralGaming />} />
          <Route path="/StandardCourses" element={<Standard_Courses />} />
          <Route path="/allcourses" element={<All_Courses />} />
          <Route path="/productdesign" element={<Product_Design />} />
          <Route path="/FrontendDevelopment" element={<Frontend />} />
          <Route path="/EmbeddedSystems" element={<Embedded_Systems />} />
          <Route path="/BackendDevelopment" element={<Backend />} />
          <Route
            path="/MobileAppDevelopment"
            element={<Mobile_Development />}
          />
          <Route
            path="/FullstackWebDevelopment"
            element={<Fullstack_Web_Dev />}
          />
          <Route
            path="/FullstackProductDesign"
            element={<Fullstack_Product />}
          />
          <Route path="/FullstackMobileApp" element={<Fullstack_Mobile />} />
          <Route path="/DataScience" element={<DataScience />} />
          <Route path="/CyberSecurity" element={<CyberSecurity />} />
          <Route path="/InnovativeShowcase" element={<Innovative_Showcase />} />
          <Route path="/Ethics" element={<Ethics />} />
          <Route path="/ThirdParties" element={<Third_Parties />} />
          <Route path="/events" element={<Events />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route
            path="/EngineeringServices"
            element={<Engineering_Services />}
          />
          <Route path="/Heurekka" element={<Heurekka />} />
          <Route path="/Medplan" element={<Medplan />} />
          <Route path="/BootcampPage" element={<Bootcamp_Page />} />
          <Route path="/GameTournament" element={<Game_Tournament />} />
          <Route path="/AwardNight" element={<Award_Night />} />
          <Route
            path="/RegularTrainingRegistration"
            element={<Regular_Registration />}
          />
          <Route
            path="/BootcampRegistration"
            element={<BootCamp_Registration />}
          />
          <Route path="/ITregistration" element={<Intern_Registration />} />
          <Route
            path="/Bootcamp/BackendDevelopment"
            element={<BootcampBackend />}
          />
          <Route
            path="/Bootcamp/FrontendDevelopment"
            element={<BootcampFrontend />}
          />
          <Route
            path="/Bootcamp/EmbeddedSystems"
            element={<BootcampEmbeddedSystems />}
          />
          <Route
            path="/Bootcamp/MobileAppDevelopment"
            element={<BootcampMobileDevelopment />}
          />
          <Route
            path="/Bootcamp/FullstackWebDevelopment"
            element={<BootcampFullstackWebDev />}
          />
          <Route
            path="/Bootcamp/FullstackProductDesign"
            element={<BootcampFullstackProduct />}
          />
          <Route
            path="/Bootcamp/FullstackMobileApp"
            element={<BootcampFullstackMobile />}
          />
          <Route
            path="/Bootcamp/ProductDesign"
            element={<BootcampProductDesign />}
          />
          <Route
            path="/Bootcamp/DataScience"
            element={<BootcampDataScience />}
          />
          <Route
            path="/Bootcamp/CyberSecurity"
            element={<BootcampCyberSecurity />}
          />
          <Route
            path="/IndustrialTraining/BackendDevelopment"
            element={<ITBackend />}
          />
          <Route
            path="/IndustrialTraining/FrontendDevelopment"
            element={<ITFrontend />}
          />
          <Route
            path="/IndustrialTraining/EmbeddedSystems"
            element={<ITEmbeddedSystems />}
          />
          <Route
            path="/IndustrialTraining/MobileAppDevelopment"
            element={<ITMobileDev />}
          />
          <Route
            path="/IndustrialTraining/FullStackWebDevelopment"
            element={<ITFullstackWeb />}
          />
          <Route
            path="/IndustrialTraining/FullstackProductDesign"
            element={<ITFullstackProductDesign />}
          />
          <Route
            path="/IndustrialTraining/FullstackMobileApp"
            element={<ITFullstackMobileDev />}
          />
          <Route
            path="/IndustrialTraining/ProductDesign"
            element={<ITProductDesign />}
          />
          <Route
            path="/IndustrialTraining/CyberSecurity"
            element={<ITCyberSecurity />}
          />
          <Route
            path="/IndustrialTraining/DataScience"
            element={<ITDataScience />}
          />
          <Route path="/explore-gaming" element={<Gaming />} />
          <Route path="/naijapharm" element={<NaijaPharm />} />
          <Route path="/ComputerNetworking" element={<ComputerNetworking />} />
          <Route 
            path="./Bootcamp/ComputerNetworking"
            element={<BootcampComputerNetworking />}               
          />
          <Route 
            path="/IndustrialTraining/ComputerNetworking"
            element={<ITComputerNetworking />}               
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
