import React from "react";
import "./ContactUs.css";
import HQMap from "../../images/map_hq.png";
import MapMobile from "../../images/map_mobile.png";
import Location from "../../images/location.png";

export default function ContactUs() {
  return (
    <div className="contact-parent">
      <h1 className="contact-title">Contacting Deebug</h1>
      <p className="contact-container-title">Education Inquiries</p>

      <div className="contact-section1-container">
        <div>
          <p className="contact-subtitle2">
            How to Register for Industrial Training
          </p>
          <p>
            As a student visit{" "}
            <a href="./IndustrialTraining">
              {" "}
              Deebug Institute Industrial Training Registration
            </a>{" "}
            or call +234 916 262 7332 <br />
            <br /> For additional information about the institute, feel free to
            reach out to us via email at{" "}
            <a href="mailto:info@deebug.org">info@deebug.org</a> or by calling
            +234 916 262 7332.
          </p>
        </div>
        <div>
          <p className="contact-subtitle2">
            How to Register for Regular Classes
          </p>
          <div>
            Visit{" "}
            <a href="./RegularStudents"> Deebug Institute Registration</a> or
            call +234 916 262 7332.
            <br />
            <br /> To register for online classes also visit
            <a href="./RegularStudents"> Deebug Institute Registration</a>. For
            more information on the institute visit, call +234 916 262 7332
          </div>
        </div>
      </div>

      <div className="contact-section1-container extra-margin-top">
        {/* <div> */}
          <p className="contact-subtitle2">
            How to Register for our free Bootcamp
          </p>
          <p>
            Visit <a href="./bootcamp"> Bootcamp Registration</a> or call +234
            916 262 7332
          </p>
        {/* </div> */}
        <div></div>
      </div>

      <div className="contact-divider" />

      <p className="contact-container-title">Service Support</p>

      <div className="contact-section1-container">
        <div>
          <p className="contact-subtitle2">To build Software Products</p>
          <p>
            Visit <a href="./engineering"> Deebug Engineering</a> or call +234
            916 262 7332.
          </p>
        </div>
      </div>

      {/* <div className=""> */}
      <div className="contact-box">
        <div className="contact-box-first">{<img src={HQMap} />}</div>

        <div className="contact-box-second">
          <div className="contact-box-address-container">
            <p className="contact-box-title">Head Office Address</p>
            <div className="contact-box-address-group">
              <img src={Location} alt="" className="contact-location-image" />
              <p className="contact-box-address">
                No 13 Akpakpava road, opposite wema bank, Ring road. Benin city,
                Edo state
              </p>
            </div>
          </div>

          <div className="contact-box-address-container">
            <p className="contact-box-title">Branch Office Address</p>

            <div className="contact-box-address-group">
              <img src={Location} alt="" className="contact-location-image" />
              <p className="contact-box-address">
                Top floor Efex 02 building, before Uselu shell, Ugbowo Benin
                city, Edo state
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
