import React from "react";
import "../Footer/Footer.css";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RightArrow from "../../images/chevron_right.png";
import LeftArrow from "../../images/chevron_left.png";
import Logo from "../../images/logo.png";
import LogoWhite from "../../images/logo_white.png";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobile, setMobile] = useState(false);
  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const is_light_footer =
    location.pathname === "/" ||
    // location.pathname === "/allcourses" ||
    location.pathname === "/education" ||
    location.pathname === "/IndustrialTrainingCourses" ||
    location.pathname === "/CareerExpedition" ||
    location.pathname === "/Healthcare" ||
    location.pathname === "/contact"
      ? true
      : false;

  let current_page = "";
  const [currentPage, setCurrentPage] = useState("");

  function get_current_page() {
    let current_page;
    if (location.pathname === "/") {
      current_page = "Home";
    } else if (location.pathname === "/deebug_leadership") {
      current_page = "Deebug Leadership";
    } else if (location.pathname === "/education") {
      current_page = "Education";
    } else if (location.pathname === "/RegularStudents") {
      current_page = "Regular Students";
    } else if (location.pathname === "/allcourses") {
      current_page = "All Courses";
    } else if (location.pathname === "/IndustrialTraining") {
      current_page = "Industrial Training";
    } else if (location.pathname === "/IndustrialTrainingCourses") {
      current_page = "Industrial Training Courses";
    } else if (location.pathname === "/hackathon") {
      current_page = "Hackathon";
    } else if (location.pathname === "/bootcamp") {
      current_page = "Bootcamp";
    } else if (location.pathname === "/gaming") {
      current_page = "Gaming";
    } else if (location.pathname === "/contact") {
      current_page = "Contact us";
    } else if (location.pathname === "/engineering") {
      current_page = "Engineering";
    } else if (location.pathname === "/Healthcare") {
      current_page = "Healthcare";
    } else if (location.pathname === "/maintenance") {
      current_page = "Maintenance";
    }
    setCurrentPage(current_page);
  }

  useEffect(() => {
    get_current_page();
  }, []);

  const bgColor = is_light_footer ? "#F3F3F3" : "#101010";

  const headerColor = is_light_footer
    ? "rgba(68, 68, 68, 1)"
    : "rgba(243, 243, 243, 0.75)";

  const linkColor = is_light_footer
    ? "rgba(68, 68, 68, 1)"
    : "rgba(243, 243, 243, 0.75)";

  const year = new Date().getFullYear();

  return (
    <div className="footer-main-container">
      {mobile ? (
        <>
          <div>
            <div
              className="footer-current-page-indicator"
              style={{
                paddingLeft: "20px",
                backgroundColor: bgColor,
              }}
            >
              {is_light_footer ? (
                <img src={Logo} className="logo_img" />
              ) : (
                <img src={LogoWhite} className="logo_img" />
              )}
              <img src={RightArrow} style={{ height: "12px", width: "8px" }} />
              <span style={{ color: linkColor }}>{currentPage}</span>
            </div>

            <div
              className="grid-container"
              style={{
                backgroundColor: bgColor,
              }}
            >
              <>
                <div
                  className="footer_top"
                  style={{
                    color: headerColor,
                  }}
                >
                  <ul className="footer_links">
                    <li>Links</li>

                    <li>
                      <a href="/education" style={{ color: linkColor }}>
                        Education
                      </a>
                    </li>

                    <li>
                      <a href="/engineering" style={{ color: linkColor }}>
                        Engineering
                      </a>
                    </li>

                    <li>
                      <a href="/Healthcare" style={{ color: linkColor }}>
                        Healthcare
                      </a>
                    </li>

                    <li>
                      <a href="/gaming" style={{ color: linkColor }}>
                        Gaming
                      </a>
                    </li>
                  </ul>

                  <ul className="footer_links">
                    <li>About Deebug</li>

                    {/* <li>
                      <a
                        href="/deebug_leadership"
                        style={{ color: linkColor }}
                      >
                        Deebug Leadership
                      </a>
                    </li> */}

                    <li>
                      <a href="/events" style={{ color: linkColor }}>
                        Events
                      </a>
                    </li>

                    <li>
                      <a href="/contact" style={{ color: linkColor }}>
                        Contact Deebug
                      </a>
                    </li>
                  </ul>

                  <ul className="footer_links">
                    <li>For Healthcare</li>

                    <li>
                      <a href="/maintenance" style={{ color: linkColor }}>
                        MedPlan
                      </a>
                    </li>

                    <li>
                      <a href="/Healthcare" style={{ color: linkColor }}>
                        NaijaPharms
                      </a>
                    </li>
                  </ul>
                </div>
              </>

              <>
                <div
                  className="footer_top"
                  style={{
                    color: headerColor,
                  }}
                >
                  <ul className="footer_links">
                    <li>Deebug Values</li>

                    <li>
                      <a href="/education" style={{ color: linkColor }}>
                        Education
                      </a>
                    </li>

                    <li>
                      <a href="/Ethics" style={{ color: linkColor }}>
                        Equity & Justice
                      </a>
                    </li>

                    <li>
                      <a href="/Ethics" style={{ color: linkColor }}>
                        Ethics
                      </a>
                    </li>
                  </ul>

                  <ul className="footer_links">
                    <li>For Education</li>

                    <li>
                      <a href="/education" style={{ color: linkColor }}>
                        Education
                      </a>
                    </li>

                    <li>
                      <a href="/Ethics" style={{ color: linkColor }}>
                        Equity & Justice
                      </a>
                    </li>
                    <li>
                      <a href="/Ethics" style={{ color: linkColor }}>
                        Ethics
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            </div>
            <div
              className="footer_bottom"
              style={{
                color: headerColor,
                backgroundColor: bgColor,
              }}
            >
              <span>Nigeria</span>

              <div style={{ fontSize: "0.8rem" }}>
                <p>Copyright © {year} Deebug org. All rights reserved</p>
                <p>
                  <a href="/maintenance" style={{ color: linkColor }}>
                    Privacy policy
                  </a>{" "}
                  |{" "}
                  <a href="/maintenance" style={{ color: linkColor }}>
                    Terms of use
                  </a>{" "}
                  |{" "}
                  <a href="/maintenance" style={{ color: linkColor }}>
                    Legal
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="footer_container"
          style={{
            backgroundColor: bgColor,
          }}
        >
          <div>
            <div className="footer-current-page-indicator">
              {is_light_footer ? (
                <img src={Logo} className="logo_img" />
              ) : (
                <img src={LogoWhite} className="logo_img" />
              )}
              <img src={RightArrow} style={{ height: "12px", width: "8px" }} />
              <span style={{ color: linkColor }}>{currentPage}</span>
            </div>

            <div
              className="footer_parent"
              style={{
                color: headerColor,
              }}
            >
              <div className="footer_top">
                <ul className="footer_links">
                  <li className="footer_header">Links</li>

                  <li>
                    <a href="/education" style={{ color: linkColor }}>
                      Education
                    </a>
                  </li>

                  <li>
                    <a href="/engineering" style={{ color: linkColor }}>
                      Engineering
                    </a>
                  </li>

                  <li>
                    <a href="/Healthcare" style={{ color: linkColor }}>
                      Healthcare
                    </a>
                  </li>

                  <li>
                    <a href="/gaming" style={{ color: linkColor }}>
                      Gaming
                    </a>
                  </li>
                </ul>

                <ul className="footer_links">
                  <li className="footer_header">About Deebug</li>

                  {/* <li>
                    <a
                      href="/deebug_leadership"
                      style={{ color: linkColor }}
                    >
                      Deebug Leadership
                    </a>
                  </li> */}

                  <li>
                    <a href="/events" style={{ color: linkColor }}>
                      Events
                    </a>
                  </li>

                  <li>
                    <a href="/contact" style={{ color: linkColor }}>
                      Contact Deebug
                    </a>
                  </li>
                  <li>
                    <a href="/education" style={{ color: linkColor }}>
                      Education
                    </a>
                  </li>

                  <li>
                    <a href="/Ethics" style={{ color: linkColor }}>
                      Equity & Justice
                    </a>
                  </li>

                  <li>
                    <a href="/Ethics" style={{ color: linkColor }}>
                      Ethics
                    </a>
                  </li>
                </ul>

                <ul className="footer_links">
                  <li className="footer_header">For Healthcare</li>

                  <li>
                    <a href="/maintenance" style={{ color: linkColor }}>
                      MedPlan
                    </a>
                  </li>

                  <li>
                    <a href="/maintenance" style={{ color: linkColor }}>
                      NaijaPharms
                    </a>
                  </li>
                </ul>

                <ul className="footer_links">
                  <li className="footer_header">For Gaming</li>

                  <li>
                    <a href="/GameTournament" style={{ color: linkColor }}>
                      Game Tournament
                    </a>
                  </li>

                  <li>
                    <a href="/gaming" style={{ color: linkColor }}>
                      Upcoming Games
                    </a>
                  </li>

                  <li>
                    <a href="/gaming" style={{ color: linkColor }}>
                      Game asset store
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer_bottom">
                <div>
                  <p style={{ color: linkColor }}>
                    Copyright © {year} Deebug org. All rights reserved
                  </p>
                  <p>
                    <a href="/maintenance" style={{ color: linkColor }}>
                      Privacy policy
                    </a>{" "}
                    |{" "}
                    <a href="/maintenance" style={{ color: linkColor }}>
                      Terms of use
                    </a>{" "}
                    |{" "}
                    <a href="/maintenance" style={{ color: linkColor }}>
                      Legal
                    </a>
                  </p>
                </div>
                <p style={{ color: linkColor }}>Nigeria</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
